<template>
  <a-modal :width="600" :visible="visible" :closable="false">
    <h5 class="mt-2">
      Export Data Pesanan
    </h5>
    <div class="mt-4">
      <label>{{ isWarehouse ? 'Gudang' : 'Bisnis' }}</label>
      <a-select
        v-model="form.selectedBusiness"
        show-search
        :filter-option="filterOption"
        :show-arrow="!$route.query.business_id"
        :disabled="!!$route.query.business_id"
        class="w-100 select-antd-default filter"
      >
        <a-select-option value="" selected hidden>
          Pilih {{ isWarehouse ? 'gudang' : 'bisnis' }}
        </a-select-option>
        <a-select-option v-for="(item, index) in businessOptions" :key="index" :value="item.value">
          {{ item.label }}
        </a-select-option>
      </a-select>
    </div>
    <div class="mt-4">
      <label>Channel</label>
      <a-select
        v-model="form.selectedSalesChannel"
        mode="multiple"
        :max-tag-count="1"
        show-search
        :disabled="!!$route.query.sales_channel_id"
        :show-arrow="!!$route.query.sales_channel_id"
        :filter-option="filterOption"
        class="w-100 select-antd-default filter"
      >
        <a-select-option value="" selected hidden>
          Pilih channel
        </a-select-option>
        <a-select-option v-for="(item, index) in options_channel" :key="index" :value="String(item.value)">
          {{ item.label }}
        </a-select-option>
      </a-select>
    </div>
    <div class="mt-4">
      <label class="m-0">Tanggal Pemesanan</label>
      <a-range-picker
        class="mt-2 w-100"
        :ranges="ranges"
        :format="dateFormat"
        :value="[form.startDate, form.endDate]"
        :placeholder="[
          $t('dashboardContent.start_date'),
          $t('dashboardContent.end_date'),
        ]"
        :disabled-date="disabledDate"
        :show-time="{ format: 'HH:mm:ss' }"
        :disabled="isCalculatingData"
        allow-clear
        @change="onChangeDate"
      >
        <DateIcon slot="suffixIcon" style="color: #999999" />
      </a-range-picker>
    </div>
    <div class="mt-4">
      <label>Status Pesanan</label>
      <a-select
        v-model="form.statusOrder"
        class="w-100 select-antd-default filter"
        :disabled="isCalculatingData"
      >
        <a-select-option value="" selected hidden>
          Pilih status pesanan
        </a-select-option>
        <a-select-option v-for="(item, index) in categoryState" :key="index" :value="index">
          {{ $t('order.filterState.' + item.title) }}
        </a-select-option>
      </a-select>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between align-items-center">
        <div class="w-50">
          <p class="m-0 text-left">
            total sales data:
            <a-spin v-if="isCalculatingData" class="ml-2" />
            <strong v-else class="ml-1">{{ totalExportRowData }}</strong>
          </p>
          <p v-if="totalExportRowData > 500" class="text-left disclaimer-max-data text-danger mt-2">
            Silahkan pilih kembali filter tanggal, maksimal yang dapat diunduh sebanyak 500 data 
          </p>
        </div>
        <div class="w-50 align-self-end">
          <a-button key="back" class="mr-2" @click="typeof back !== 'undefined' && back(), resetData(), onClose()">
            Cancel
          </a-button>
          <a-button
            key="submit"
            :loading="isCalculatingData"
            :disabled="isCalculatingData || !form.startDate || !form.endDate || (!form.statusOrder && form.statusOrder !== 0) || !form.selectedBusiness || !form.selectedSalesChannel || !totalExportRowData || totalExportRowData > 500"
            type="primary"
            @click="onSubmitModal()"
          >
            Export Excel
          </a-button>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script>
import getQueryParam from '@/utils/getQueryParam'
import DateIcon from '@/components/Icons/DateV2.vue'
import debounce from 'lodash/debounce'
import { listChannel } from '@/api/business'

export default {
  name: 'ModalExportExcel',
  components: {
    DateIcon,
  },
  mixins: [getQueryParam],
  props: {
    visible: Boolean,
    back: Function,
    onClose: Function,
    categoryState: Array,
    totalExportRowData: Number,
  },
  data() {
    return {
      ranges: {
        'Hari ini': [this.$moment().startOf('day'), this.$moment().endOf('day')],
        'Bulan ini': [this.$moment().startOf('month').startOf('day'), this.$moment().endOf('month').endOf('day')],
        '7 Hari Terakhir': [this.$moment().subtract(7, 'days').startOf('day'), this.$moment().endOf('day')],
        '30 Hari Terakhir': [this.$moment().subtract(30, 'days').startOf('day'), this.$moment().endOf('day')],
      },
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      form: {
        startDate: '',
        endDate: '',
        statusOrder: '',
        selectedBusiness: '',
        selectedSalesChannel: '',
      },
      isCalculatingData: false,
      totalRowData: 0,
      channelOptions: [],
    }
  },
  computed: {
    isWarehouse() {
      return this.$store.state.user.restriction_base === 'Warehouse'
    },
    integration() {
      return this.$store.state.integration
    },
    businessOptions() {
      let list = []
      const value = this.$store.state.user.restriction_base === 'Business'
        ? 'business_id'
        : 'warehouse_id'
      const label = this.$store.state.user.restriction_base === 'Business'
        ? 'business_name'
        : 'name'
      this.$store.state.user.businessList.forEach(business => {
        if(business.business_id !== 'all') {
          list.push({
            value: business[value],
            label: business[label],
          })
        }
      })
      return list
    },
    getSalesChannelCode() {
      const selectedChannel = this.channelOptions.find((obj) => obj.value === this.form.selectedSalesChannel)
      if (selectedChannel) {
        return selectedChannel.channelCode
      }
      return null
    },
    options_channel() {
      const listChannels = []
      this.$store.state?.channel?.saleschannel?.forEach(item => {
        const findChannel = this.$store.state.business.listChannels.find(channel => {
          return channel.status?.toLowerCase() === 'connected' && channel.sales_channel.code === item.code
        })
        if(findChannel?.id) {
          listChannels.push({
            value: item.id,
            label: item.name,
          })
        }
      })
      listChannels.unshift(
        {
          "label": "Semua Channel",
          "value": "ALL",
        },
      )
      return listChannels
    },
  },
  watch: {
    'visible': {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (!!value) {
          const { business_id, sales_channel_id } = this.$route.query
          this.form.selectedBusiness = business_id ?? ''
          let arrSalesChannel = []
          if (sales_channel_id) {
            arrSalesChannel.push(String(sales_channel_id))
          }
          this.form.selectedSalesChannel = arrSalesChannel || []
        }
      },
    },
    '$route.query.business_id': {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.form.selectedBusiness = value ?? ''
      },
    },
    'form': {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value.startDate && value.endDate && (value.statusOrder || value.statusOrder === 0) && value.selectedBusiness && value.selectedSalesChannel) {
          this.isCalculatingData = true
          this.onCalculateRowData()
        }
      },
    },
    'form.selectedBusiness': {
      deep: true,
      immediate: true,
      handler: async function (value) {
        if (value) {
          await listChannel({ business_id: value, limit: 50 })
          .then(({ data }) => {
            const connectedListChannel = data?.data?.filter((obj) => obj.status?.toUpperCase() === 'CONNECTED')
            this.channelOptions = connectedListChannel.map((obj) => ({
              value: obj.sales_channel.id,
              label: obj.title,
              channelCode: obj.sales_channel.code,
            }))
            this.form.selectedSalesChannel = this.$route.query.sales_channel_id
              ? this.$route.query.sales_channel_id
              : ''
          })
        } 
      },
    },
    'totalExportRowData': {
      deep: true,
      handler: function (value) {
        if (value !== null) {
          this.isCalculatingData = false
        }
      },
    },
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().includes(input.toLowerCase())
    },
    to(url) {
      const route = typeof url === 'object' ? url : { path: url }
      route.query = {
        ...this.to.query,
        ...this.getQueryParam({ ...this.$route.query }),
        ...url.params?.query,
      }
      
      return route
    },
    onMinimizeNotification() {
      this.onClose()
      this.$store.commit('exportData/SET_STATE_EXPORT_DATA', {
        exportFileNotification: true,
      })
    },
    disabledDate(current) {
      return current && (current < this.$moment().subtract(91, 'day'))
    },
    onChangeDate(date) {
      this.form.startDate = date[0]
        ? this.$moment(date[0]).startOf('day').format(this.formatDate)
        : ''
      this.form.endDate = date[1]
        ? this.$moment(date[1]).endOf('day').format(this.formatDate)
        : ''
    },
    onCalculateRowData: debounce(function() {
      const selectedData = {
        startDate: this.form.startDate,
        endDate: this.form.endDate,
        statusOrder: this.categoryState.at(this.form.statusOrder),
        selectedBusiness: this.form.selectedBusiness,
        selectedSalesChannelId: this.form.selectedSalesChannel.filter((obj) => obj !== 'ALL'),
        selectedSalesChannelCode: this.getSalesChannelCode,
      }
      this.$emit('handleCheckTotalRowData', selectedData)
    }, 1000),
    onSubmitModal() {
      const countDiffDate = this.$moment(this.form.endDate).diff(this.$moment(this.form.startDate), 'days')
      if (countDiffDate > 31) {
        return this.$notification.error({
          message: 'Maximum date range for export data is 31 days.',
          description: `The date range you choose is currently ${countDiffDate} days`,
        })
      }
      const selectedData = {
        startDate: this.form.startDate,
        endDate: this.form.endDate,
        statusOrder: this.categoryState.at(this.form.statusOrder),
        selectedBusiness: this.form.selectedBusiness,
        selectedSalesChannelId: this.form.selectedSalesChannel,
        selectedSalesChannelCode: this.getSalesChannelCode,
      }
      this.$emit('handleSubmitModal', selectedData)
      this.resetData()
    },
    resetData() {
      this.form.startDate = ''
      this.form.selectedBusiness = ''
      this.form.endDate = ''
      this.form.statusOrder = ''
    },
  },
}
</script>

<style lang="scss">
.disclaimer-max-data {
  font-size: 12px;
}
</style>